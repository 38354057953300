const SEO = [
	{
		page: "home",
		description:
			"I have experience in building web applications using various frameworks and technologies.",
		keywords: ["Long", "Long T", "Long Tran"],
	},
	{
		page: "projects",
		description:
			"I have experience in building web applications using Java, Python, JavaScript, TypeScript, React.js, Node.js, Express.js, Next.js, SQL, MySQL, PostgreSQL.",
		keywords: ["Long", "Long T", "Long Tran"],
	},
];

export default SEO;
